import $ from 'jquery';
window.jQuery = $;
require('slick-carousel');

export default {
  init() {
    // JavaScript to be fired on all pages

    const windowWidth = $(window).width();

    // Check Window Width for Nav Toggle
    if(windowWidth < 1248) {
      // Navigation Toggle
      $('#nav-primary a').click(e => {
        const classCheck = $('#header').hasClass('is-active');
        if(!classCheck || $(e.currentTarget).attr('href') === '#close') { e.preventDefault(); }
        $('#header').toggleClass('is-active');
      });
    }

    // Release: Photo Gallery
    const dataPhotos = $('.photos-primary');
    dataPhotos.slick({
      arrows: false,
      adaptiveHeight: true,
      fade: true
    });
    let dataPhotoCurrent = dataPhotos.slick('slickCurrentSlide');

    const dataPhotosGrid = $('.photos-additional');
    const dataPhotosGridCheck = dataPhotosGrid.length > 0;
    if(dataPhotosGridCheck) {
      dataPhotosGrid.find('img:first-child').addClass('is-active');
      dataPhotosGrid.children().click((e) => {
        dataPhotoCurrent = $(e.currentTarget).index();
        dataPhotos.slick('slickGoTo', dataPhotoCurrent);
      });
    }
    dataPhotos.on('beforeChange', () => {
      if(dataPhotosGridCheck) {
        dataPhotosGrid.find('img').removeClass('is-active');
      }
    });
    dataPhotos.on('afterChange', (e, slick, currentSlide) => {
      dataPhotosGrid.children().eq(currentSlide).addClass('is-active');
    });

    // Modal: Initiate & Close
    let modalTarget = '';
    $('.modal-trigger').click((e) => {
      e.preventDefault();
      modalTarget = $(e.currentTarget).attr('href');
      $(modalTarget).addClass('is-active');
    });
    $('.modal-close, .modal-underlay').click((e) => {
      e.preventDefault();
      $(modalTarget).removeClass('is-active');
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after init JS is fired

    // Past shows filters
    $('.filter select').change(function() {
      runAllFilters();
    });

    function runAllFilters() {
      let list = $('.shows-past li');
      const listSize = list.length;
      $(list).hide();

      // Get all filter values
      const year = $('select#filter-year').val();
      const city = $('select#filter-city').val();
      const band = $('select#filter-band').val();

      // Filter based on all of them
      list = list.filter((i,e) => {
        return RegExp(year).test($(e).data('year')) &&
            RegExp(city).test($(e).data('city')) &&
            RegExp(band).test($(e).data('bands'));
      });

      if(list.length === 0) {
        $('.results-count').remove();
        $('.shows-past').append('<li class="results-none"><strong>No shows</strong> matching these filters</li>');
      } else if(list.length === listSize) {
        $('.results-count, .results-none').remove();
      } else {
        $('.shows-past').prepend(`<li class="results-count"><strong>${list.length} show${list.length > 1 ? 's' : ''}</strong> listed below</li>`);
        $('.results-none').remove();
      }

      list.each((i,e) => {
        $(e).show();
      });
    }
  },
};
